import * as React from "react"

export const GraphIcon = ({
  fill = "#54DD8B",
  width = "61",
  height = "55",
  transform = null,
  size = null,
}) => (
  <svg
    width={size || width}
    height={size || height}
    viewBox="0 0 61 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform={transform}
  >
    <path
      d="M54.2903 54.8698C43.2896 54.8698 41.1675 42.975 39.2929 32.4878C37.7608 23.886 36.3048 15.7708 30.5 15.7708C24.6951 15.7708 23.3153 23.86 21.7737 32.4183C19.8895 42.9403 17.7389 54.8698 6.70967 54.8698C6.45729 54.8698 6.21524 54.7783 6.03678 54.6153C5.85831 54.4524 5.75806 54.2314 5.75806 54.001C5.75806 53.7705 5.85831 53.5495 6.03678 53.3866C6.21524 53.2236 6.45729 53.1321 6.70967 53.1321C16.1211 53.1321 18.0434 42.4537 19.899 32.1316C21.5739 22.826 23.1535 14.0331 30.5 14.0331C37.8464 14.0331 39.5022 22.8608 41.1675 32.2098C43.0042 42.4972 44.9074 53.1321 54.2903 53.1321C54.5427 53.1321 54.7847 53.2236 54.9632 53.3866C55.1416 53.5495 55.2419 53.7705 55.2419 54.001C55.2419 54.2314 55.1416 54.4524 54.9632 54.6153C54.7847 54.7783 54.5427 54.8698 54.2903 54.8698Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.25"
    />
    <path
      d="M59.0484 54.8698H1.95161C1.69923 54.8698 1.45718 54.7783 1.27872 54.6153C1.10026 54.4524 1 54.2314 1 54.0009V1.86887C1 1.63843 1.10026 1.41743 1.27872 1.25448C1.45718 1.09154 1.69923 1 1.95161 1C2.204 1 2.44604 1.09154 2.62451 1.25448C2.80297 1.41743 2.90323 1.63843 2.90323 1.86887V53.1321H59.0484C59.3008 53.1321 59.5428 53.2236 59.7213 53.3866C59.8997 53.5495 60 53.7705 60 54.0009C60 54.2314 59.8997 54.4524 59.7213 54.6153C59.5428 54.7783 59.3008 54.8698 59.0484 54.8698Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.25"
    />
  </svg>
)
