import * as React from "react"

export const ResearchIcon = () => (
  <svg
    width="47"
    height="47"
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.0208 44.6425L32.8208 31.3945C36.0714 27.73 37.7708 22.9381 37.5582 18.0364C37.3457 13.1347 35.2379 8.5092 31.6824 5.14193C28.1269 1.77466 23.4037 -0.0691457 18.5156 0.00198408C13.6276 0.0731138 8.95962 2.05358 5.50288 5.52289C2.04614 8.9922 0.0728489 13.6771 0.00197689 18.583C-0.0688951 23.4888 1.76823 28.2292 5.1233 31.7976C8.47837 35.366 13.0872 37.4815 17.9711 37.6948C22.855 37.9081 27.6295 36.2025 31.2808 32.9401L44.4808 46.1881C44.5795 46.2937 44.6988 46.3779 44.8313 46.4355C44.9637 46.493 45.1065 46.5227 45.2508 46.5227C45.3951 46.5227 45.5379 46.493 45.6703 46.4355C45.8027 46.3779 45.922 46.2937 46.0208 46.1881C46.126 46.089 46.2099 45.9693 46.2672 45.8364C46.3245 45.7035 46.3541 45.5602 46.3541 45.4153C46.3541 45.2705 46.3245 45.1272 46.2672 44.9943C46.2099 44.8614 46.126 44.7417 46.0208 44.6425ZM18.8508 35.4793C15.5874 35.4793 12.3973 34.5081 9.68388 32.6885C6.97047 30.8688 4.85562 28.2825 3.60678 25.2566C2.35793 22.2306 2.03118 18.901 2.66783 15.6886C3.30449 12.4763 4.87596 9.52561 7.18353 7.20965C9.49109 4.89369 12.4311 3.31651 15.6318 2.67754C18.8325 2.03856 22.1501 2.36651 25.1651 3.61989C28.18 4.87328 30.757 6.99582 32.57 9.7191C34.3831 12.4424 35.3508 15.6441 35.3508 18.9193C35.3508 23.3113 33.6124 27.5234 30.5181 30.629C27.4237 33.7346 23.2269 35.4793 18.8508 35.4793Z"
      fill="#54DD8B"
    />
  </svg>
)
