import * as React from "react"

export const DollarIcon = () => (
  <svg
    width="29"
    height="52"
    viewBox="0 0 29 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3766 24.464V5.776C21.4647 6.136 26.2778 10.168 26.2778 15.096C26.2778 15.584 26.6683 15.976 27.1544 15.976C27.6405 15.976 28.0309 15.584 28.0309 15.096C28.0309 9.208 22.4289 4.384 15.3766 4.016V0.88C15.3766 0.392 14.9861 0 14.5 0C14.0139 0 13.6234 0.392 13.6234 0.88V4.016C6.56312 4.384 0.96109 9.216 0.96109 15.096C0.96109 20.976 6.56312 25.8 13.6234 26.168V44.864C7.53531 44.512 2.71421 40.472 2.71421 35.544C2.71421 35.056 2.32375 34.664 1.83765 34.664C1.35156 34.664 0.96109 35.056 0.96109 35.544C0.96109 41.432 6.56312 46.264 13.6234 46.632V50.328C13.6234 50.816 14.0139 51.208 14.5 51.208C14.9861 51.208 15.3766 50.816 15.3766 50.328V46.632C22.4289 46.256 28.0309 41.432 28.0309 35.544C28.0309 29.664 22.4289 24.84 15.3766 24.464ZM2.72218 15.096C2.72218 10.176 7.54328 6.136 13.6314 5.776V24.4C7.53531 24.048 2.72218 20.016 2.72218 15.096ZM15.3766 44.864V26.232C21.4647 26.592 26.2778 30.624 26.2778 35.544C26.2858 40.464 21.4647 44.504 15.3766 44.864Z"
      fill="#54DD8B"
    />
  </svg>
)
