import * as React from "react"
import styled from "styled-components"

interface IProps {
  text: string
  styledFragment: string[] | string
  underlinedFragment: string[] | string
}

const splitTextByHighlights = (
  text: string,
  styledFragments?: string[],
  underlinedFragments?: string[]
) => {
  let splitText = [text]

  styledFragments.forEach(styledFragment => {
    let newSplitText: string[] = []
    splitText.forEach(text => {
      //make sure we don't split text that's already styledFragmented
      const shouldBeSplit =
        text.includes(styledFragment) && !styledFragments.some(h => text === h)
      newSplitText = shouldBeSplit
        ? newSplitText.concat(
            text.split(styledFragment)[0],
            styledFragment,
            text.split(styledFragment)[1]
          )
        : newSplitText.concat(text)
    })
    splitText = newSplitText
  })

  underlinedFragments.forEach(underlinedFragment => {
    let newSplitText: string[] = []
    splitText.forEach(text => {
      //make sure we don't split text that's already underlined
      const shouldBeSplit =
        text.includes(underlinedFragment) &&
        !styledFragments.some(h => text === h)
      newSplitText = shouldBeSplit
        ? newSplitText.concat(
            text.split(underlinedFragment)[0],
            underlinedFragment,
            text.split(underlinedFragment)[1]
          )
        : newSplitText.concat(text)
    })
    splitText = newSplitText
  })

  return splitText
}

const getStyledSpans = (
  splitText: string[],
  styledFragments: string[],
  underlinedFragments: string[]
) => {
  return splitText.map((fragment, index) => {
    if (styledFragments.includes(fragment)) {
      return <TextWithStyle key={index}>{fragment}</TextWithStyle>
    } else if (underlinedFragments.includes(fragment)) {
      return (
        <span
          style={{
            lineHeight: 0.85,
            display: "inline-block",
            borderBottom: "1px solid currentColor",
          }}
          key={index}
        >
          {fragment}
        </span>
      )
    } else {
      return <span key={index}>{fragment}</span>
    }
  })
}

export const StyledText = (props: IProps): JSX.Element => {
  const styledFragments = Array.isArray(props.styledFragment)
    ? props.styledFragment
    : [props.styledFragment]

  const underlinedFragments = Array.isArray(props.underlinedFragment)
    ? props.underlinedFragment
    : [props.underlinedFragment]

  const splitText: string[] = splitTextByHighlights(
    props.text,
    styledFragments,
    underlinedFragments
  )

  return <>{getStyledSpans(splitText, styledFragments, underlinedFragments)}</>
}
const TextWithStyle = styled.span`
  font-family: Gentona-Light, system-ui, sans-serif;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.headingAccent};
`

StyledText.defaultProps = {
  text: "",
  styledFragment: [],
  underlinedFragment: [],
}
