import * as React from "react"
import { Flex } from "rebass/styled-components"
import styled from "styled-components"

interface IFancyImage {
  imageElement: React.ReactElement
  circleColor?: string
  leafColor?: string
  leafStyle?: React.CSSProperties
  circleStyle?: React.CSSProperties
  circleSize?: number
  style?: React.CSSProperties
  imageWrapperCss?: string
  circleCss?: string
  leafCss?: string
}

export const FancyImage = ({
  imageElement,
  circleColor,
  leafColor,
  circleStyle,
  circleSize,
  leafStyle,
  style,
  circleCss,
  imageWrapperCss,
  leafCss,
}: IFancyImage) => (
  <Styled style={style} css={imageWrapperCss}>
    <ImageWrapper>
      {leafColor && <Leaf color={leafColor} style={leafStyle} css={leafCss} />}
      {imageElement}
      {circleColor && (
        <Circle
          color={circleColor}
          style={circleStyle}
          css={circleCss}
          size={circleSize}
        />
      )}
    </ImageWrapper>
  </Styled>
)

const Styled = styled(Flex)`
  margin: 0;
  width: 100%;
  max-height: 300px;

  position: relative;
  z-index: 1;
  > svg {
    position: absolute;
  }

  height: fit-content;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    height: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin-left: -85px;
    max-height: 500px;
    width: calc(100% + 2 * 45px);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    max-height: 700px;
    margin: 0;
    width: 100%;
  }
`

const ImageWrapper = styled(Flex)`
  z-index: 2;
  position: relative;

  * {
    background-color: transparent !important;
  }

  img {
    z-index: 3;
  }

  > * {
    width: 100%;
  }

  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    height: auto;

    > * {
      width: 100%;
    }

    img {
      object-fit: contain !important;
    }
  }

  ${({ css }) => css};
`

interface ISvgProps {
  style?: React.CSSProperties
  color: string
  size?: number
  css?: string
}

const Circle = ({ style, color, size = 283, css }: ISvgProps) => (
  <ResponsiveCircle
    className="circle"
    style={style}
    css={css}
    size={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={size} height={size} rx={size / 2} fill={color} />
  </ResponsiveCircle>
)

const Leaf = ({ style, color, css }: ISvgProps) => (
  <ResponsiveLeaf
    className="leaf"
    style={style}
    viewBox="0 0 241 341"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={css}
  >
    <path
      d="M0 122.5C0 54.8451 54.8451 0 122.5 0H241V341H0V122.5Z"
      fill={color}
    />
  </ResponsiveLeaf>
)

const ResponsiveLeaf = styled.svg<{
  style: React.CSSProperties
  color?: string
  css?: string
}>`
  width: 60px;
  transform: translate(15px, 0);
  position: absolute;
  left: -23px;
  bottom: 0px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    bottom: 45px;
    width: 100px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 141px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 241px;
    height: 341px;
    bottom: 55px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[3]}) {
  }
  @media screen and (min-width: 1152px) {
    bottom: 45px;
  }
`

const ResponsiveCircle = styled.svg<{ size: number; css?: string }>`
  position: absolute;
  right: -50px;
  bottom: 90px;

  width: 144px;
  height: 144px;
  transform: translate(-40px, 80px);

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    right: 0px;
    bottom: 90px;
    width: 206px;
    height: 206px;
    transform: translate(-20px, 20px);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    right: -50px;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    right: -30px;
  }
`
