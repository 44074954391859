import * as React from "react"

export const ManIcon = () => (
  <svg
    width="21"
    height="58"
    viewBox="0 0 21 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5057 10.4003C13.2644 10.4003 15.5098 8.12 15.5098 5.3168C15.5098 2.5136 13.2651 0.233276 10.5057 0.233276C7.74627 0.233276 5.50159 2.5136 5.50159 5.3168C5.50159 8.12 7.74627 10.4003 10.5057 10.4003ZM10.5057 2.15328C12.2224 2.15328 13.6198 3.57216 13.6198 5.3168C13.6198 7.06144 12.2224 8.48032 10.5057 8.48032C8.78829 8.48032 7.39159 7.06144 7.39159 5.3168C7.39159 3.57216 8.78892 2.15328 10.5057 2.15328Z"
      fill="#54DD8B"
    />
    <path
      d="M14.4413 11.0422H6.55872C3.04521 11.0422 0.187531 13.9459 0.187531 17.5146V33.903C0.187531 35.3277 1.32846 36.4861 2.76234 36.4861C2.98914 36.4861 3.20523 36.4457 3.41502 36.3888V54.4323C3.41502 56.271 4.88733 57.7667 6.69732 57.7667H6.79497C8.57661 57.7667 10.0262 56.2941 10.0262 54.4841V37.1773H10.9001V54.4841C10.9001 56.2941 12.3497 57.7667 14.1786 57.7667C16.0163 57.7667 17.5113 56.248 17.5113 54.3811V36.36C17.7519 36.4342 18.0033 36.4861 18.2698 36.4861C19.6722 36.4861 20.8125 35.327 20.8125 33.903V17.5146C20.8125 13.9459 17.9542 11.0422 14.4413 11.0422ZM18.9225 33.903C18.9225 34.2685 18.6295 34.5661 18.2377 34.5661C17.8779 34.5661 17.585 34.2685 17.585 33.903V18.5533C17.585 18.0227 17.1616 17.5933 16.64 17.5933C16.1183 17.5933 15.695 18.0227 15.695 18.5533V33.0384C15.6477 33.153 15.6213 33.2784 15.6213 33.4102V54.3817C15.6213 55.1901 14.9743 55.8473 14.1313 55.8473C13.3917 55.8473 12.7901 55.2361 12.7901 54.4848V36.2173C12.7901 35.6873 12.3667 35.2573 11.8451 35.2573H9.08124C8.55897 35.2573 8.13624 35.6873 8.13624 36.2173V54.4841C8.13624 55.2355 7.53459 55.8467 6.79497 55.8467H6.69732C5.92935 55.8467 5.30502 55.2125 5.30502 54.4323V33.903V33.4096V18.5533C5.30502 18.0227 4.88229 17.5933 4.36002 17.5933C3.83775 17.5933 3.41502 18.0227 3.41502 18.5533V33.4102V33.9037C3.41502 34.2691 3.1227 34.5667 2.73021 34.5667C2.37048 34.5667 2.07753 34.2691 2.07753 33.9037V17.5146C2.07753 15.0045 4.08786 12.9622 6.55872 12.9622H14.4413C16.9121 12.9622 18.9225 15.0045 18.9225 17.5146V33.903Z"
      fill="#54DD8B"
    />
  </svg>
)
