import * as React from "react"
// @ts-ignore
import storymapImage from "../../images/homepage/storymap.png"

export const StorymapImageWithShapes = () => {
  return (
    <svg width="403" height="220" viewBox="0 0 403 220" fill="none">
      <path
        d="M0 103C0 46.1147 46.1147 0 103 0V170H0V103Z"
        fill="#CF4520"
        transform="translate(0, 20)"
      />
      <rect x="238" y="55" width="165" height="165" rx="82.5" fill="#DDCF54" />
      <path
        d="M15 12H379V120C379 170.81 337.81 212 287 212H15V12Z"
        fill="url(#pattern0)"
      />

      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0"
            transform="translate(0 -0.689021) scale(0.00060423 0.0010997)"
          />
        </pattern>
        <image
          id="image0"
          width="1655"
          height="2508"
          xlinkHref={storymapImage}
        />
      </defs>
    </svg>
  )
}
