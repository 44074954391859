import * as React from "react"
// @ts-ignore
import image from "../../images/homepage/data-explorer.png"

export const DataExplorerImageWithShapes = () => {
  return (
    <svg width="403" height="250" viewBox="0 0 403 220" fill="none">
      <path
        d="M398 132C398 188.885 351.885 235 295 235V65H398V132Z"
        fill="#54A6DD"
        transform="translate(-8, -30)"
      />
      <rect width="165" height="165" rx="82.5" fill="#54DDD0" />
      <path
        d="M15 12H379V120C379 170.81 337.81 212 287 212H15V12Z"
        fill="url(#pattern1)"
        transform="translate(0, -20)"
      />
      <defs>
        <pattern
          id="pattern1"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image1"
            transform="translate(0 -0.689021) scale(0.00060423 0.0010997)"
          />
        </pattern>
        <image id="image1" width="1655" height="2508" xlinkHref={image} />
      </defs>
    </svg>
  )
}
